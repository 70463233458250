/**
 * Reducer to get the average of an array of numbers. Always provide the initial
 * value of 0 -- it will throw if you don't, to avoid a `TypeError: Reduce of
 * empty array with no initial value` in production.
 * ```typescript
 * [1, 2, 3, 4, 5].reduce(meanReducerFactory(), 0); // 3
 * ```
 */
export function meanReducerFactory() {
  let firstIteration = true;
  return function meanReducer(
    sum: number,
    currentValue: number,
    index: number,
    arr: number[]
  ): number {
    if (firstIteration && index === 1) {
      throw Error(
        'Mean reducer requires an initial value of 0 to prevent "TypeError: Reduce of empty array with no initial value" errors. [].reduce(meanReducerFactory(), 0)'
      );
    }
    firstIteration = false;
    if (index)
      if (index === arr.length - 1) {
        return (sum + currentValue) / arr.length;
      }
    return sum + currentValue;
  };
}
